<template>
    <div class="report-page">
        <div class="main-h1">Отчет</div>
        <div class="card">
            <div class="card-body">
                <div class="card-body-title">Период</div>
                <div class="row">
                    <div class="col-6">
                        <label class="form-label">
                            <div class="t">От</div>
                            <input type="date" v-model="dateFrom" @change="getReport"/>
                        </label>
                    </div>
                    <div class="col-6">
                        <label class="form-label">
                            <div class="t">До</div>
                            <input type="date" v-model="dateTo" @change="getReport"/>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <table class="table">
                    <thead>
                        <th>Город</th>
                        <th>Дел поступило</th>
                        <th>Мировое</th>
                        <th>Без рассмотрения</th>
                        <th>Сумма арбитражного сбора</th>
                        <th>Гонорар арбитров</th>
                        <th>Гонорар агентов</th>
                        <th>Дел с возвратом</th>
                        <th>Возврат арбитражного сбора</th>
                    </thead>
                    <tbody>
                        <tr v-for="city in cities">
                            <td>{{city.title}}</td>
                            <td>{{city.income_count.toLocaleString()}}</td>
                            <td>{{city.peace_count.toLocaleString()}}</td>
                            <td>{{city.without_consideration_count.toLocaleString()}}</td>
                            <td>{{city.amount_sbor_in.toLocaleString()}}</td>
                            <td>{{city.amount_arbiter.toLocaleString()}}</td>
                            <td>{{city.amount_agent.toLocaleString()}}</td>
                            <td>{{city.amount_out_claims_count}}</td>
                            <td>{{city.amount_sbor_out.toLocaleString()}}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="btn blue" @click="download()">Скачать</div>
            </div>
        </div>
    </div>
</template>

<script>
import XLSX from 'xlsx'
import moment from 'moment'
import claimList from '../../../components/claim/list.vue';
export default {
    name: "reportPage",
    components:{claimList},
    data() {
        return {
            dateFrom:moment().subtract(1,'year').format('Y-MM-DD'),
            dateTo:moment().format('Y-MM-DD'),
            cities:[],
        }
    },
    methods: {
        getReport() {
            this.$api.get(`report/${this.dateFrom}/${this.dateTo}`).then(res => {
                if (res.data.success) {
                    this.cities = res.data.data
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        download() {
            const worksheet = XLSX.utils.json_to_sheet(this.cities.map(item => {delete item.id;return item;}));
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, `${this.dateFrom} - ${this.dateTo}`);
            XLSX.utils.sheet_add_aoa(worksheet, [['Город','Дел поступило','Мировое соглашение','Без рассмотрения','Дел с возвратом','Сумма арбитражного сбора','Возврат арбитражного сбора','Гонорар арбитров','Гонорар агентов']], { origin: "A1" });
            worksheet["!cols"] = [ { wch: 20 },{ wch: 20 },{ wch: 20 },{ wch: 20 },{ wch: 20 },{ wch: 30 },{ wch: 30 },{ wch: 20 },{ wch: 20 } ];
            XLSX.writeFile(workbook, `report-${this.dateFrom}___${this.dateTo}.xlsx`, { compression: true });
        }
    },
    created() {
        this.getReport();
    }
}
</script>

<style scoped>
.btn {
    display: inline-block;
    margin: 20px 0 0;
}
</style>